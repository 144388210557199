// @ts-nocheck
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import React, {Suspense, lazy} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';

import {LoaderV} from '../widgets/LoaderV';
import {useAuth} from '../services/auth-service/auth.provider';

const DashboardScreen = lazy(() => import('../screens/Dashboard'));
const LandingScreen = lazy(() => import('../screens/LandingPage'));
const LoginScreen = lazy(() => import('../screens/Login'));
const SignupScreen = lazy(() => import('../screens/Signup'));
const VerifyUserScreen = lazy(() => import('../screens/VerifyUser'));
const RegistrationScreen = lazy(() => import('../screens/Registration'));
const ForgotPasswordScreen = lazy(() => import('../screens/ForgotPassword'));
const ResetPasswordScreen = lazy(() => import('../screens/ResetPassword'));
const TermsandConditionScreen = lazy(
  () => import('../screens/TermsAndCondition'),
);
const PrivacyScreen = lazy(() => import('../screens/PrivacyPolicy'));
const ViewCertificateScreen = lazy(
  () => import('../screens/VerifyCertificate'),
);
const FaqScreen = lazy(() => import('../screens/Faqs'));
const NotFoundScreen = lazy(() => import('../screens/PageNotFound'));
const VerifierScreen = lazy(() => import('../screens/VerifierView'));

const {isAuthenticated} = useAuth();

const routes = [
  {
    path: '/login',
    component: LoginScreen,
  },
  {
    path: '/signup',
    component: SignupScreen,
  },
  {
    path: '/register',
    component: RegistrationScreen,
  },
  {
    path: '/dashboard',
    component: DashboardScreen,
    protected: true,
  },
  {
    path: '/verifyUser',
    component: VerifyUserScreen,
    protected: true,
  },
  {
    path: '/credential',
    component: ViewCertificateScreen,
  },
  {
    path: '/hello',
    component: NotFoundScreen,
  },
  {
    path: '/termsandcondition',
    component: TermsandConditionScreen,
  },
  {
    path: '/privacy',
    component: PrivacyScreen,
  },
  {
    path: '/forgotpassword',
    component: ForgotPasswordScreen,
  },
  {
    path: '/resetpassword',
    component: ResetPasswordScreen,
  },
  {
    path: '/',
    component: LandingScreen,
  },
];

const RouterConfig = props => (
  <Switch>
    <Route path="/login" component={LoginScreen} />
    <Route path="/signup" component={SignupScreen} />
    <Route path="/register" component={RegistrationScreen} />
    <PrivateRoute path="/dashboard" component={DashboardScreen} />
    <Route path="/hello" component={NotFoundScreen} />
    <Route path="/termsandcondition" component={TermsandConditionScreen} />
    <Route path="/privacy" component={PrivacyScreen} />
    <Route path="/forgotpassword" component={ForgotPasswordScreen} />
    <Route path="/resetpassword" component={ResetPasswordScreen} />
    <Route path="/credential" component={VerifierScreen} />
    <Route path="/faq" component={FaqScreen} />
    <Route path="/" component={LandingScreen} />
  </Switch>
);

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )
    }
  />
);

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const Stack = createStackNavigator();

const AppNavigatorInternal = (): JSX.Element => {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode={'none'}>
        <Stack.Screen name="Dashboard" component={DashboardScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export const AppNavigator = (): JSX.Element => {
  return (
    <Router>
      <Suspense fallback={<LoaderV loadingText={'loading'} />}>
        <RouterConfig />
      </Suspense>
    </Router>
  );
};
